import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import EnquiryForm from '../components/EnquiryForm';

const EnquiryPage = () => {
  const {
    datoCmsEnquiry: { seoMetaTags, title, bannerText },
  } = useStaticQuery(graphql`
    query EnquiryPageQuery {
      datoCmsEnquiry {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        bannerText
      }
    }
  `);

  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner
          heading={title}
          text={bannerText}
          isContact={true}
          center={true}
        />
        <EnquiryForm />
      </main>
    </Layout>
  );
};

export default EnquiryPage;
