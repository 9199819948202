import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  sectionMargins,
  minBreakpointQuery,
  fontSize,
  inputStyles,
  textAreaStyles,
  formButtonStyles,
  fontWeights,
  maxBreakpointQuery,
  standardColours,
} from '../styles';
import { Container, Heading, ArrowButton, Svg } from './ui';
import mailSentIcon from '../images/mail-sent.inline.svg';

const StyledEnquiryForm = styled.section`
  ${maxBreakpointQuery.large`
    ${sectionMargins()};
  `}
`;

const StyledForm = styled.div`
  ${minBreakpointQuery.large`
    margin-top: -100px;
    position: relative;
    background-color: ${standardColours.white};
    padding: 75px 100px;
  `}
`;

const StyledHeading = styled(Heading)`
  ${maxBreakpointQuery.large`
    text-align: center;
  `}
`;

const StyledInner = styled.form`
  margin-top: 20px;
  display: grid;
  gap: 15px;

  ${minBreakpointQuery.small`
    margin-top: 30px;
    gap: 20px;
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
    gap: 25px;
  `}
`;

const StyledLabel = styled.label`
  ${({ full }) => {
    if (full) {
      return css`
        grid-column: 1 / 3;
      `;
    } else {
      return css`
        ${maxBreakpointQuery.large`
          grid-column: 1 / 3;
        `}
      `;
    }
  }}
`;

const StyledLabelText = styled.span`
  font-weight: ${fontWeights.medium};
`;

const StyledInput = styled.input`
  ${inputStyles()};
`;

const StyledTextArea = styled.textarea`
  ${textAreaStyles()};
`;

const StyledSubmit = styled(ArrowButton)`
  ${formButtonStyles()};

  ${maxBreakpointQuery.large`
    grid-column: 1 / 3;
  `}

  ${minBreakpointQuery.large`
    grid-column-start: 2;
  `}
`;

const StyledThankYou = styled.div``;

const StyledIcon = styled(Svg)`
  width: 43px;
  height: 43px;

  ${minBreakpointQuery.large`
    vertical-align: top;
  `}
`;

const StyledThankYouHeading = styled(Heading)`
  margin: 20px 0;

  ${minBreakpointQuery.small`
    margin-top: 25px;
    margin-bottom: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 0;
    margin-bottom: 30px;
    display: inline-block;
    margin-left: 20px;
  `}
`;

const StyledText = styled.p`
  ${fontSize(18)};

  ${minBreakpointQuery.small`
    ${fontSize(20)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(22)};
  `}
`;

const StyledLink = styled(ArrowButton)`
  ${formButtonStyles()};
  margin-top: 25px;

  ${minBreakpointQuery.small`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.large`
    max-width: 50%;
    margin-top: 35px;
  `}
`;

const EnquiryForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    const form = document.getElementById('enquiry-form');
    const formData = new FormData(form);

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setFormSubmitted(true);
      })
      .catch(error => alert(error));
  };

  return (
    <StyledEnquiryForm>
      <Container>
        <StyledForm>
          {!formSubmitted ? (
            <>
              <StyledHeading>Complete your details below</StyledHeading>
              <StyledInner
                id="enquiry-form"
                name="enquiry-form"
                method="POST"
                data-netlify="true"
                onSubmit={e => handleSubmit(e)}
              >
                <input type="hidden" name="form-name" value="enquiry-form" />
                <StyledLabel full={true}>
                  <StyledLabelText>Full name</StyledLabelText>
                  <StyledInput type="text" name="name" required />
                </StyledLabel>
                <StyledLabel full={true}>
                  <StyledLabelText>E-mail address</StyledLabelText>
                  <StyledInput type="email" name="email" required />
                </StyledLabel>
                <StyledLabel>
                  <StyledLabelText>Phone number</StyledLabelText>
                  <StyledInput type="tel" name="phone" required />
                </StyledLabel>
                <StyledLabel>
                  <StyledLabelText>Company name</StyledLabelText>
                  <StyledInput type="text" name="company-name" required />
                </StyledLabel>
                <StyledLabel full={true}>
                  <StyledLabelText>Message</StyledLabelText>
                  <StyledTextArea name="message" />
                </StyledLabel>
                <StyledSubmit>Submit Form</StyledSubmit>
              </StyledInner>
            </>
          ) : (
            <StyledThankYou>
              <StyledIcon image={mailSentIcon} />
              <StyledThankYouHeading>
                Thank you for your Enquiry
              </StyledThankYouHeading>
              <StyledText>
                Thank you for taking the time to submit your enquiry, we’ll get
                back to you as soon as possible.
              </StyledText>
              <StyledLink to="/">Go Back Home</StyledLink>
            </StyledThankYou>
          )}
        </StyledForm>
      </Container>
    </StyledEnquiryForm>
  );
};

export default EnquiryForm;
